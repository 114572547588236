@charset "UTF-8";
/*
    - Liquet - Creative Agency/Personal React Website
    - © raduCio
    - First of all, we'd like to thank you for buying our product. We hope it'll be a problem solver for you. Cheers!
*/
/***** BOOTSTRAP *****/
@import '~bootstrap/dist/css/bootstrap.min.css';
/***** STYLING *****/
/***** FONTS *****/
/* Fontface */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
/***** GENERAL *****/
/* General Styling */
.container {
  max-width: 140rem;
}
@media only screen and (min-width: 100rem) {
  .container {
    padding: 0 5rem;
  }
}

:root {
  --bg: #f4f4f4;
  --black-text: #212838;
  --grey-text: #212838;
  --light-text: #646974;
  --accent: #D5D6D1;
  --white-text: #fff;
  --white: #fff;
}

body {
  background: var(--bg) !important;
  color: var(--black-text);
  font-family: "Suisse Int'l", "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: -0.02em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  padding: 0px 4vw;
}

.container-fluid-small {
  padding: 0px 15vw;
  width: 100%;
}

.page-trans {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

.img-anim {
  position: relative;
  overflow: hidden;
}
.img-anim img {
  max-width: 100%;
  width: 100%;
  transform: scale(1.1);
}
.img-anim .img-anim-bg {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: var(--bg);
  z-index: 999;
  top: 0;
  right: 0;
}

h1 {
  line-height: 1.25;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: var(--black-text);
}
h1.section-big-title {
  font-size: 2.5rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.65;
  color: var(--grey-text);
}

a:hover, a:focus {
  text-decoration: none;
}

.header-anim {
  will-change: transform;
  transform: translateY(15px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.header-anim-on {
  will-change: transform;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.hero-title {
  will-change: transform;
  transform: translateY(25px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.hero-title-on {
  will-change: transform;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.hero-cta {
  will-change: transform;
  transform: translateY(25px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.hero-cta-on {
  will-change: transform;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.img-anim-bg-on {
  width: 0vw !important;
  transition: all 0.3s ease-in-out;
}

.img-anim-on img {
  transform: scale(1) !important;
  transition: all 0.5s ease-in-out;
}

.anim-bot {
  will-change: transform;
  transform: translateY(50px);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.item-anim-bot {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 0.5s ease-in-out;
}

.anim-bot-single-img {
  will-change: transform;
  transition: all 0.5s ease-in-out;
}

.item-anim-bot-single-img {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 0.5s ease-in-out;
}

.green-bg {
  background: var(--black-text);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.green-anim {
  width: 0;
  transition: all 0.5s ease-in-out;
}

.item-anim-green {
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.hidden-xs {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hidden-xs {
    display: block;
  }
}

.xs-only {
  display: block;
}
@media only screen and (min-width: 768px) {
  .xs-only {
    display: none;
  }
}

.lead-dark {
  background: #212838;
}

.bg-impact {
  background: #E9ECF0;
}

.section-lead {
  padding: 5rem 0;
}
@media only screen and (min-width: 992px) {
  .section-lead {
    padding: 15vh 0;
  }
}

h3.lead-title, .lead-title {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
}
h3.lead-title .text-muted, .lead-title .text-muted {
  font-feature-settings: "fake" 1, "zero" 1;
}

h4.lead-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3333;
  margin-bottom: 0.75em;
}

.lead-content, .lead-text, p.lead-text {
  font-size: 1rem;
  line-height: 1.5;
}

.lead-small, p.lead-small {
  font-size: 1rem;
  line-height: 1.5;
}

.tech-item {
  display: flex;
  align-items: center;
  margin: 1em 0;
}
.tech-item img {
  margin-right: 1em;
  height: 12vw;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .tech-item img {
    height: 3vw;
  }
}
.highlighted-link {
  color: var(--black-text);
  position: relative;
}
.highlighted-link::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  right: 0;
  height: 10%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.highlighted-link:hover {
  color: var(--light-text);
}
.highlighted-link:hover::after {
  background: rgba(0, 0, 0, 0.2);
}

#heroSection {
  position: relative;
  padding: 10rem 0;
  background: #E9ECF0;
  display: flex;
  align-items: center;
}
#heroSection h1 {
  line-height: 1.25;
  font-weight: 400;
  font-size: 3rem;
}
#heroSection p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
}
#heroSection .hero-button {
  margin-top: 3rem;
}
#heroSection .hero-button a {
  color: var(--black-text);
  font-size: 1.25vw;
  font-weight: 400;
  padding: 17px 17px 17px 25px;
  border: 1px solid #dbdbdb;
  transition: all 0.3s ease-in-out;
}
#heroSection .hero-button a:hover {
  background: var(--accent);
  border: 1px solid var(--accent);
  transition: all 0.3s ease-in-out;
}
#heroSection .about-hero h1 {
  margin-bottom: 1.5rem;
}

.lead-btn {
  font-size: 1.25rem;
  padding: 1em 2.5em;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.lead-btn:hover {
  background: #fff;
  color: var(--black-text);
}

.lead-work-item {
  margin-bottom: 3rem;
}

.vw-pt-3 {
  padding-top: 3vw;
}

.vw-pb-3 {
  padding-bottom: 3vw;
}

.vw-py-3 {
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.team-member {
  text-align: center;
}
.team-member img {
  width: 100%;
  border-radius: 100rem;
}
.team-member .lead-subtitle {
  margin-top: 0.75em;
  margin-bottom: 0.25em;
}

@media only screen and (min-width: 992px) {
  .lead-btn {
    font-size: 1.25vw;
  }
  h4.lead-subtitle {
    font-size: 1.75vw;
  }
  h3.lead-title, .lead-title {
    font-size: 2vw;
  }
  .lead-content, .lead-text, p.lead-text {
    font-size: 1.75vw;
  }
  .lead-small, p.lead-small {
    font-size: 1.25vw;
  }
  #heroSection {
    height: 70vh;
  }
  #heroSection h1 {
    font-size: 5vw;
  }
  #heroSection p {
    font-size: 2vw;
  }
  .lead-work-item {
    margin-bottom: 3vw;
  }
}
/***** HEADER *****/
/* Header styling */
header {
  padding: 1.5rem 0 1.5rem 0;
  position: absolute;
  position: sticky;
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
header .site-logo {
  display: flex;
  align-items: center;
}
header .site-logo a {
  color: var(--text);
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0px;
}
header .bayLogo {
  height: 2rem;
}
header .main-menu {
  display: flex;
  justify-content: flex-end;
}
header .main-menu ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
}
header .main-menu ul li {
  display: inline-block;
}
header .main-menu ul li a {
  color: var(--light-text);
  font-size: 1rem;
  margin: 0 0 0 50px;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}
header .main-menu ul li a.active {
  color: var(--white-text);
}
header .main-menu ul li a:hover {
  color: var(--white-text);
  border-bottom: 1px solid var(--white);
  transition: all 0.3s ease-in-out;
}

@media (min-width: 992px) and (max-width: 1499px) {
  header .bayLogo {
    height: 2.5vw;
  }
}
@media only screen and (min-width: 1500px) {
  header {
    padding: 1.5vw 0 1.5vw 0;
  }
  header .bayLogo {
    height: 3vw;
  }
  header .main-menu {
    display: flex;
    justify-content: flex-end;
  }
  header .main-menu ul li {
    display: inline-block;
  }
  header .main-menu ul li a {
    font-size: 1.25vw;
    margin: 0 0 0 3vw;
  }
}
/***** HOME *****/
/* Files for Homepage styling */
/* HomeCta component styling */
#homeCtaSection {
  padding-bottom: 125px;
}
#homeCtaSection .row {
  margin-top: 125px;
}
#homeCtaSection h1 {
  margin-bottom: 1.5rem;
}
#homeCtaSection .single-work-cta ul {
  columns: 2;
}
#homeCtaSection ul {
  margin-top: 20px;
  list-style: none;
  padding-left: 0px;
}
#homeCtaSection ul li {
  color: var(--light-text);
  font-size: 1rem;
  font-weight: 400;
  line-height: 2;
}
#homeCtaSection img {
  max-width: 100%;
}
#homeCtaSection p {
  margin-top: 7px;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 25px;
}
#homeCtaSection p:last-child {
  margin-bottom: 0px;
}
#homeCtaSection .img-anim {
  width: 100%;
}

/***** CONTACT *****/
/* Contact page styling */
.contact-section .contact-right input {
  width: 100%;
  background: transparent;
  border: 0;
  border: 1px solid #dbdbdb;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  font-weight: 400;
  font-size: 1em;
  color: var(--grey-text);
  transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}
.contact-section .contact-right input::placeholder {
  color: var(--light-text);
  font-weight: 400;
}
.contact-section .contact-right input:focus {
  outline: 0;
  background: #fff;
  border-color: var(--dark-text);
  transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}
.contact-section .contact-right textarea {
  width: 100%;
  background: transparent;
  border: 0;
  border: 1px solid #dbdbdb;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  font-weight: 400;
  min-height: 145px;
  font-size: 1em;
  color: var(--grey-text);
  transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}
.contact-section .contact-right textarea::placeholder {
  color: var(--light-text);
  font-weight: 400;
}
.contact-section .contact-right textarea:focus {
  outline: 0;
  transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
  background: #fff;
  border-color: var(--dark-text);
}
.contact-section .contact-right button {
  border: 1px solid var(--accent);
  background: var(--accent);
  color: var(--text);
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  padding: 13px 18px;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.contact-section .contact-right button:focus {
  outline: 0;
  text-decoration: none;
}
.contact-section .success-message {
  padding-top: 10px;
  text-align: center;
}
.contact-section .success-message i {
  font-size: 24px;
  color: var(--light-text);
}
.contact-section .success-message p {
  color: var(--light-text) !important;
  text-align: center;
}

/***** FOOTER *****/
.footer {
  padding: 8rem 0;
}

@media only screen and (min-width: 992px) {
  .footer {
    padding: 5vw 0;
  }
}
/***** RESPONSIVE DESIGN *****/
/* Responsive Design */
@media (max-width: 1300px) {
  .container-fluid {
    padding: 0 2rem;
  }
  header {
    padding-top: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .container-fluid {
    padding: 0 25px;
  }
  header {
    padding-top: 2.5rem;
  }
  h1.section-big-title {
    font-size: 1.75rem;
  }
  #singleNews .single-news-right {
    margin-top: 30px;
  }
}
@media (max-width: 992px) {
  #sectionFooter .social-footer-menu ul {
    text-align: right;
    margin-bottom: 5px;
  }
  #sectionFooter .social-footer-menu ul li {
    margin-left: 20px;
    margin-right: 0px;
  }
  #sectionFooter .social-footer-menu ul li a {
    font-size: 0.9rem;
  }
  #sectionFooter .social-footer-menu p {
    text-align: right;
    font-size: 0.8rem;
  }
}
@media (max-width: 768px) {
  .container-fluid {
    padding: 0 1.5rem;
  }
  .container {
    padding: 0 1.5rem;
  }
  h1.section-big-title {
    font-size: 1.25rem;
    letter-spacing: -0.5px;
  }
  header {
    padding-top: 1.5rem;
  }
  #homeCtaSection {
    padding-bottom: 50px;
  }
  #homeCtaSection .row {
    margin-top: 50px;
  }
  #homeCtaSection .row h1 {
    margin-bottom: 15px;
  }
  #homeCtaSection .row ul {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  #homeCtaSection .row ul li {
    font-size: 0.9rem;
  }
  #homeCtaSection .row p {
    font-size: 0.9rem;
  }
  #sectionContact {
    padding-bottom: 75px;
  }
  #sectionContact .contact-left {
    margin-bottom: 30px;
  }
  #sectionContact .contact-left ul {
    margin-top: 20px;
  }
  #sectionContact .contact-left h2 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 24px;
  }
  #sectionContact .contact-left h6 {
    margin-bottom: 0px;
  }
  #sectionContact .contact-left h6.phone {
    margin-top: 8px;
  }
  #sectionContact .contact-left h5 {
    margin-bottom: 15px;
  }
  #sectionContact .contact-left ul {
    text-align: left;
    margin-bottom: 10px;
  }
  #sectionContact .contact-left ul li {
    margin-left: 0px;
    margin-right: 20px;
  }
  #sectionContact .contact-left p {
    text-align: left;
  }
  #sectionFooter .footer-contact-box h6 {
    margin-bottom: 0px;
    font-size: 0.9rem;
  }
  #sectionFooter .footer-contact-box h6.phone {
    margin-top: 8px;
  }
  #sectionFooter .footer-contact-box h5 {
    margin-bottom: 15px;
    font-size: 0.95rem;
    margin-top: 5px;
  }
  #sectionFooter .footer-address-box {
    margin-top: 0px;
  }
  #sectionFooter .footer-logo h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  header {
    padding-top: 25px;
  }
  header .main-menu {
    justify-content: flex-end;
  }
  header .main-menu ul li {
    text-align: right;
  }
  header .main-menu ul li a {
    margin: 0 0 0 1rem;
  }
  #sectionFooter .footer-address-box {
    margin-top: 10px;
  }
  #sectionFooter .social-footer-menu {
    margin-top: 20px;
  }
  #sectionFooter .social-footer-menu ul {
    text-align: left;
    margin-bottom: 5px;
  }
  #sectionFooter .social-footer-menu ul li {
    margin-left: 0px;
    margin-right: 20px;
  }
  #sectionFooter .social-footer-menu ul li a {
    font-size: 0.9rem;
  }
  #sectionFooter .social-footer-menu p {
    text-align: left;
    font-size: 0.8rem;
  }
}
@media (max-width: 500px) {
  #heroSection {
    padding-right: 0px;
  }
  #heroSection h1 {
    padding-right: 50px;
  }
  #heroSection h1 br {
    display: none;
  }
  #heroSection.short {
    height: 65vh !important;
    padding-top: 75px;
  }
}
@media (max-width: 360px) {
  .container-fluid {
    padding: 0 15px;
  }
  .container {
    padding: 0 15px;
  }
  #heroSection {
    height: 95vh !important;
  }
  .short {
    height: 90vh !important;
  }
  #portfolioSection .portfolio-filters .portfolio-nav a {
    font-size: 0.85rem;
    padding: 8px 12px;
    margin-right: 10px;
  }
}

